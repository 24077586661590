.main
    lost-utility clearfix
    padding 4em 0
    min-height 400px
    min-height 40vh
    +above('l')
        padding 1em 0

    &--frontpage
        padding 1em 0

    &--padded
        padding 3em 0 1em

    +below('s')
        padding 2em 0

.content
    lost-column 8/12
    lost-move 4/12
    font-size 1rem

    &--no-sidebar
        lost-column 1/1
        lost-move 0

        .hamburger
            display block
            +above('m')
                display none

    &--full-width
        lost-column 1/1
        lost-move 0

    &__lead
        @extends $colored
            
    &__lead,
    &__text
        lost-column 1/2
        font-size 1.3rem
        line-height 1.3
        +below('m')
            lost-column 1/1
            lost-move 0

    h2
        margin 2em 0 1em

    .hamburger
        display none
        position relative
        top 0
        margin-bottom 3em

    +below('m')
        lost-column 1/1
        lost-move 0
        .hamburger
            display block

.sidebar
    lost-column 3/12
    lost-move -8/12
    @extends $colored
    +above('l')
        padding-top 2em

    +below('m')
        lost-column 1/1
        lost-move 0
        margin-top 3em
        .hamburger-block
            display none

    &__block
        margin-bottom 5em
        +below('s')
            margin-bottom 3em

        &--small
            margin-bottom 3em
            +below('s')
                margin-bottom 2em

        &--may-be-col
            +at('m')
                lost-column 1/2

.mention
    &__image
        max-width 100%
        margin 1em 0 0

    &__name
        @extends $uppercase-spaced
        font-size .8rem

    &__title
        font-weight 500
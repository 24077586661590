html
    +below('s')
        font-size 90%

body
    font-family $font-family-sans-serif;
    font-weight 300
    font-size 16px
    color #333
    line-height 1.1
    border 16px solid transparent

    // lost-utility edit
    &.framed--inverted
        color #fff
        background primary-color

.frame
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    z-index 5000
    pointer-events none
    border 16px solid primary-color

    .framed--inverted &
        border-color #fff

    +below('s')
        border-width 8px

a
    @extends $colored
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd
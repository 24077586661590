.person-contact-form
    max-height 0
    margin-bottom 4em
    overflow hidden
    transition max-height .3s ease-out

    &--open
        max-height 600px

    .mainheading
        font-size 1.5rem
        margin-bottom 1em
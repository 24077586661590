.nav-meta
    lost-column 4/12 2
    text-align right
    text-transform uppercase

    +below('m')
        lost-column 3/12 2

    +below('s')
        display none

    .nav-item
        display inline-block
        margin-left .5em
        &:first-child
            margin-left 0
        &--login
            margin-right 60px
        &--icon
            position absolute
            right 0
            bottom 16px

    img
        width 34px

    .nav-link
        margin-top -.3em
        line-height 1


.nav-items
    margin 0
    padding 0
    list-style none

.nav-main
    lost-column 8/12 2
    text-transform uppercase
    +below('m')
        lost-column 9/12 2
    +below('s')
        lost-column 1/1
        text-align center

.nav-main,
.nav-meta
    font-size .9rem
    padding-top 5.5em
    +below('m')
        padding-bottom 2em
        .nav-item
            font-size .85rem



.nav-item
    @extends $uppercase-spaced
    &.mobile-only
        +above('m')
            display none !important

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    padding-bottom 2em
    +below('m')
        padding-bottom 0
    margin-right 1.5em
    position relative
    &:last-child
        margin-right 0

    +below('l')
        margin-bottom 1em
    +below('s')
        lost-column 1/1
        margin .5em 0
        font-size 1rem

    &--active > .nav-link,
    &--child-active > .nav-link
        color primary-color

    &:hover .nav-sub
        display block

.framed--inverted .nav-main .nav-item
    .nav-link
        color black
    &--active > .nav-link,
    &--child-active > .nav-link
        color inherit

.nav-link
    display block
    color black-color
    font-weight 300

.nav-main .nav-sub
    position absolute
    display none
    text-align center
    left 50%
    top 30px
    padding 0
    min-width 175px
    background primary-color
    box-shadow 0 2px 6px rgba(0, 0, 0, .1)
    transform translateX(-50%)
    z-index 99
    padding 0.25em 0
    .framed--inverted &
        background white
        a.nav-link
            color primary-color
        &:before
            border-bottom: 5px solid white;
    &:before
        content ""
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid primary-color;
        bottom 100%
        position absolute

    .nav-item
        display block
        margin 0
        padding-bottom 0

    .nav-link
        display block
        margin 0
        padding .8em
        color white

.nav-main .nav-item--has-children
    +below('m')
        display none

.submenu--items
    display none
.nav-main .submenu--items
    +below('m')
        display block

.hamburger
    height 35px
    background url('hamburger.svg') no-repeat 0 0/50px;

    .framed--inverted &
        background-image url('hamburger-white.svg')

.nav--with-logo
    +below('m')
        display none
    .small-logo
        lost-column 4/12 3
        padding-top 3.5em
        padding-bottom 0
        img
            width 40px
            height 40px

    .nav-main
        lost-column 7/12 3
        padding-top 6.2em
        padding-bottom 0
        .nav-item
            +below('l')
                margin-right 1.2em
                font-size .9em
    .nav-meta
        lost-column 1/12 3
        padding-top 8.5em
        padding-bottom 0

.socialmedia
    margin 0
    padding 0
    list-style none
    line-height 1
    float left

    &__entry
        float left
        margin-right .5em

    &__link
        display block
        padding .8em 0 .7em
        text-align center

    img
        height 32px

.sponsor-grid
    lost-utility clearfix
    .sponsor
        lost-waffle 1/3
        height 240px
        background no-repeat center center/cover
        +below('m')
            lost-column 1/3
            height 240px

        +below('s')
            lost-column 1/2
            height 300px

        +below(570px)
            height 240px

        +below('xs')
            lost-column 1/1
            height 240px

        &--small
            lost-column 1/3
            display block
            background no-repeat center center/cover
            +below('m')
                lost-column 1/3

            +below('s')
                lost-column 1/2

            +below('xs')
                lost-column 1/1

        &__image
            height 170px
            lost-align middle-left
            img
                max-width 100%
                &.full
                    height 100%

        &__title
            lost-align center
            height 170px
            position relative
            width 100%
            display block
            border 5px solid primary-color
            padding 1.5rem
            text-align center

        &__name
            line-height 1.3
            font-size 1rem
            margin 1em 0

        a
            display block
            height 100%

@font-face {
    font-family: 'Walsheim';
    src: url('fonts/gtwalsheim-webfont.eot');
    src: url('fonts/gtwalsheim-webfont.eot?#iefix') format('embedded-opentype'),
            url('fonts/gtwalsheim-webfont.woff2') format('woff2'),
            url('fonts/gtwalsheim-webfont.woff') format('woff'),
            url('fonts/gtwalsheim-webfont.ttf') format('truetype'),
            url('fonts/gtwalsheim-webfont.svg#gt_walsheimregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Walsheim';
    src: url('fonts/gtwalsheim-medium-webfont.eot');
    src: url('fonts/gtwalsheim-medium-webfont.eot?#iefix') format('embedded-opentype'),
            url('fonts/gtwalsheim-medium-webfont.woff2') format('woff2'),
            url('fonts/gtwalsheim-medium-webfont.woff') format('woff'),
            url('fonts/gtwalsheim-medium-webfont.ttf') format('truetype'),
            url('fonts/gtwalsheim-medium-webfont.svg#gt_walsheimmedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Walsheim';
    src: url('fonts/gtwalsheim-bold-webfont.eot');
    src: url('fonts/gtwalsheim-bold-webfont.eot?#iefix') format('embedded-opentype'),
            url('fonts/gtwalsheim-bold-webfont.woff2') format('woff2'),
            url('fonts/gtwalsheim-bold-webfont.woff') format('woff'),
            url('fonts/gtwalsheim-bold-webfont.ttf') format('truetype'),
            url('fonts/gtwalsheim-bold-webfont.svg#gt_walsheimbold') format('svg');
    font-weight: bold;
    font-style: normal;
}
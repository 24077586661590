.text-right
    text-align right !important

.text-center
    text-align center !important

.text-red
    color #f00

.text-sm-center
    +below('m')
        text-align center

.col-50
    lost-column 1/2
    +below('m')
        lost-column 1/1

.m-t
    margin-top 2em

.dim > div:not(:first-child)
    opacity .5
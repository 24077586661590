.contribution-items
    display flex
.article
    &__header
        margin-bottom 2em

    &__image
        margin-bottom 2em
        margin-top 2em
        max-width 100%
        max-height 425px
        &+header h2.subheading
            margin-top 0

    &__video
        video
            margin-bottom 2em
            max-width 100%
            max-height 425px

    &__backTo
        padding 0.5rem 0.75rem
        background primary-color
        color white
        display inline-block
        box-shadow 3px 3px 5px 0px rgba(0,0,0,.2)
        .framed--inverted &
            background white
            color primary-color

    img
        max-width 100%

    &__lead
        @extends $big-heading
        margin-bottom 1em

    &__content
        margin-bottom 2em
        font-size 1.3rem
        line-height 1.3

        p
            margin 0 0 1em

    &__dates
        margin-bottom 2em
        line-height 1.5

    &__images
        margin-bottom 2em

    &__meta
        lost-utility clearfix
        margin-bottom 2em
        margin-top -1em

    &__related
        @extends $colored

    &__authors > .article-author
        lost-column 1/2
        max-width 280px
        @extends $colored
        +below('s')
            lost-column 1/1 0 0

    &__copyright div
        float left
        width calc(100% - 20px)
        &:first-child
            width 20px
            padding-right 1rem

.lg-sub-html
    background primary-color !important
    color white !important
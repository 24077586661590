.bs-callout
    line-height 1.7
    background primary-color
    font-size 1.1rem
    color #fff
    border none
    padding 1em
    text-align center

.clearfix .h-bordered
    margin-bottom 2em
    margin-top 4em
    color primary-color

.no-margin-top
    margin-top 0 !important

.desktop-only
    +below('m')
        display none
.mobile-only
    display none
    +below('m')
        display block
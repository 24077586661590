.logo
    lost-column 1/1
    font-size 2rem
    font-weight 500
    color primary-color
    text-align center

    +below('s')
        font-size 1.8rem

    img
        width 50px
        height 50px
        margin-top .8em
        +below('s')
            width 40px
            height 40px

.logo,
.small-logo
    a
        color inherit
        display block

.small-logo a
    width 40px
    height 40px

.header
    padding-top 3em
.labelled-icon
    display flex
    align-items center
    +below('s')
        + .labelled-icon
            margin-top 4rem

    &__icon
        width 32px
        flex-shrink 0
        margin-right 10px
        img
            max-width 100%

    &__label
        margin-right 40px
        span
            display block
            color black
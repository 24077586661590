.profile
    lost-utility clearfix
    margin-bottom 4em
    position relative
    &__image
        lost-column 7/10 2
        +below('m')
            lost-column 5/8 2
        +below('s')
            lost-column 1/1
    &__links
        lost-column 3/10 2
        +above('m')
            position absolute
            right 0
            height 100%
        +below('m')
            lost-column 3/8 2
        +below('s')
            lost-column 1/1

.tiles
    padding-left 0
    list-style-type none
    +above('m')
        position absolute
        bottom 0
        margin-bottom 3px
    .tile
        display block
        @extends $colored
        text-align center
        border-bottom 1px solid primary-color

        &__image
            +below('l')
                width 60px

        &__text
            @extends $uppercase-spaced
            font-size .9rem
            line-height 1.4
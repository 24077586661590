project-grid-gutter = 20px

.project-grid
    &--padding-top
        padding-top 2rem

    &__item
        width "calc(33% - (%s / 2))" % project-grid-gutter
        height 160px
        background no-repeat center center / cover
        transition transform .5s ease
        +below('m')
            width "calc(50% - (%s / 2))" % project-grid-gutter
        +below('s')
            width 100%
            height 100px

        &:hover
            transform scale(.975)

        &--height2
            height 300px
            +below('s')
                height 200px

        &--height3
            height 480px
            +below('s')
                height 300px

        &--width-1_5
            width "calc(50% - (%s / 2))" % project-grid-gutter
            +below('s')
                width 100%

        &--width2
            width 66%
            +below('m')
                width "calc(50% - (%s / 2))" % project-grid-gutter
            +below('s')
                width 100%

        &--width3
            width 100%

        a
            display block
            height 100%

    .project
        //border 8px solid primary-color
        //padding project-grid-gutter
        color primary-color
        min-height 300px

        a
            padding project-grid-gutter
            position relative
            z-index 2

        &:after
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            display block
            content ""
            border 8px solid primary-color

        &--inverted
            background primary-color
            color #fff

            &:after
                content none

            .project__title,
            .project__author
                color #fff

        &__title
            @extends $big-heading
            font-size 1.4rem
            font-weight 500
            margin-top 0
            +below('xs')
                font-size 1.25rem

        &__author
            @extends $uppercase-spaced
            margin-bottom project-grid-gutter
            text-align center

    .art
        &__caption
            position absolute
            bottom 16px
            right 20px
            left 20px

        span
            display inline
            position relative
            z-index 1
            background primary-color
            padding 1px 0
            box-decoration-break clone
            box-shadow 4px 0 0 primary-color, -4px 0 0 primary-color;

        &--inverted span
            background #fff
            color primary-color
            box-shadow 4px 0 0 #fff, -4px 0 0 #fff;

        &__title
            color #fff
            font-weight 500
            line-height 1.6
            margin-bottom 0
            margin-top 0

        &__author
            color #fff
            @extends $uppercase-spaced
            font-weight normal
            margin-bottom .3em
            font-size .8rem




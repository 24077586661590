.contribution-items
  display flex
  flex-wrap wrap
  justify-content space-between
  width 100%
  list-style none
  margin 0
  padding 0

  .contribution
    border 8px solid primary-color
    padding project-grid-gutter
    color primary-color
    min-height 300px
    width "calc(50% - (%s / 2))" % project-grid-gutter
    margin-bottom project-grid-gutter

    &:hover
        background-color primary-color

    a
      display block
      height 100%
      width 100%
      &:hover
        background-color primary-color
        .contribution__title
          color white

    +below('s')
      width 100%

    &:first-child
      width 100%

    &:last-child
      width 100%
      background-color primary-color
      .contribution__title,
      .contribution__author
        color white

    &__title
      @extends $big-heading
      font-size 1.4rem
      font-weight 500
      margin-top 0
      +below('xs')
        font-size 1.25rem

      span
        display inline-block
        margin-right .25rem

    &__author
      text-transform uppercase
      @extends $uppercase-spaced
      font-weight normal
      margin-bottom .3em
      margin-top .5rem
      font-size .8rem

.article__audios
  list-style none
  display flex
  margin 0
  padding 0
  flex-wrap wrap
  .audio__item
    width "calc(50% - (%s / 2))" % project-grid-gutter
    margin-bottom project-grid-gutter
    +below('s')
      width 100%

.article__documents
  list-style none
  display flex
  margin 0
  padding 0
  flex-wrap wrap
  .document__item
    width "calc(50% - (%s / 2))" % project-grid-gutter
    margin-bottom project-grid-gutter
    +below('s')
      width 100%
.no-margin-top.people-title
    margin-top 1.8rem !important

.people-grid
    lost-utility clearfix
    .person
        lost-waffle 1/4
        height 340px
        background no-repeat center center/cover
        +below('m')
            lost-column 1/3
            height 240px

        +below('s')
            lost-column 1/2
            height 300px

        +below(570px)
            height 240px

        +below('xs')
            lost-column 1/1
            height 340px

        &__image
            max-width 100%
            width 100%

        &__name
            line-height 1.3

        a
            display block
            height 100%

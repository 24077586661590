.footer
    lost-utility clearfix
    background #2F2F2F
    padding 2em 0
    color #fff

    a
        color #aaa

.copyright
    background #252525
    text-align center
    color #fff
    padding .9em 0 .7em
    font-size .8rem
    p
        margin 0

    a
        color inherit
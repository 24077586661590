.sidebar__download
    background primary-color
    padding 1rem
    transition transform 0.5s ease
    color #fff

    .h-bordered
        border-color white


    &:hover
        transform scale(0.95)

    a
        color #fff
        border 1px solid white

    &-icon
        padding 1.5em 0 1em
        text-align center

    img
        width 30%
        min-width 75px
        max-width 90px

.sidebar_downloads
    &:hover
        transform none
    a
        border none
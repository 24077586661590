.item-list
    margin 0
    padding 0
    list-style none

    &+h4
        margin-top 3rem

    &__image
        lost-column 1/3
        img
            max-width 100%

        +below('m')
            display none

    &__content
        lost-column 2/3

        +below('m')
            lost-column 1/1

    &--bigger-headings
        .item__heading
            +below('m')
                font-size 1.2rem

    .mobile-only
        line-height 1.4
        font-size 1rem

    h3
        font-weight 500
        font-size 1.2rem
        margin-bottom .2em
.item
    border-bottom 1px solid primary-color
    .framed--inverted &
        border-bottom-color #fff

    &:last-child
        border-bottom none

    padding-bottom 10px
    &__subheading
        letter-spacing 2px
        text-transform uppercase
        font-size .8rem
        margin 10px 0
        &--no-padding-top
            margin-top 0
        img
            margin 10px 0

    &__title
        font-weight 300

    &__heading
        font-weight 500
        font-size 1rem
        margin 0
        line-height 1.3

    &__image img
        max-width 100%

    &__meta
        @extends $uppercase-spaced
        font-size .7rem
        line-height 1.6
        margin 10px 0 0
        +.item__meta
            margin-top 0

    &--active
        position relative
        a
            position relative
            z-index 2
            color white
            .framed--inverted &
                color primary-color

    &--active:before
        content ''
        top -11px
        left -10px
        right -10px
        bottom -1px
        display block
        position absolute
        background primary-color
        z-index 0
        .framed--inverted &
            background white

.search
    lost-column 8/12
    +below('m')
        lost-column 1/1

.search-form
    lost-utility row
    margin-bottom 8em

.search-input
    lost-column 9/12
    +below('m')
        lost-column 1/1

.search-submit
    lost-column 3/12
    padding .6em 1em
    +below('m')
        lost-column 1/1

.ss-result {
    margin-bottom: 2em;
    clear: both;
}
.ss-result__aside {
    float: right;
    margin-left: .5em;
}
.ss-result__title {
    font-weight: bold;
    margin-bottom: .5em;
}
.ss-result__image{
    width 60px
}
.ss-result__badge {
    font-size: .7em;
    padding: .2em .5em;
    border-radius: 4px;
    margin-left: .75em;
    background: #eee;
    display: inline-block;
}
.ss-result__text {
    margin-bottom: .5em;
    line-height 1.3;
}
.ss-result__url {
}
$colored
    color primary-color
    .framed--inverted &
        color #fff

$big-heading
    font-size 1.9rem
    line-height 1.2
    @extends $colored
    +below('s')
        font-size 1.4rem

$uppercase-spaced
    letter-spacing 2px
    text-transform uppercase


.h-bordered
    padding-bottom 10px
    font-weight normal
    border-bottom 1px solid primary-color
    margin-top 0
    margin-bottom 0
    .framed--inverted &
        border-bottom-color #fff

.uppercase
    @extends $uppercase-spaced
    @extends $colored

.subheading
    @extends $uppercase-spaced
    @extends $colored
    font-size 1rem
    +below('s')
        font-size .8rem

    font-weight normal
    margin 0

.mainheading
    @extends $colored
    font-weight 500
    font-size 3rem
    margin 0
    line-height 1.1
    +below('s')
        font-size 2.4rem

h3.underlined
    font-weight normal
    font-size 1.3rem
    text-decoration underline
@import 'bootstrap/forms'

.form .btn-submit
    text-transform uppercase
    letter-spacing 1px
    +below('s')
        display block
        width 100%


.form-group
    margin-bottom 1.2em
    label
        margin-bottom 3px
        font-weight 300
        color primary-color
        text-transform uppercase
        font-size .9rem
        letter-spacing 1px

    &.has-error label
        color primary-color

        .inline-error
            background primary-color
            color $state-danger-bg
            display inline-block
            padding 6px 6px 4px
            line-height 1
            font-size .8em
            border-radius 4px
            margin-left 10px
            margin-bottom 1px

    .form-control
        border none
        border-bottom 1px solid primary-color
        border-radius 0
        box-shadow none
        font-size 1.1rem
        padding 0

        &:focus
            box-shadow none

.bs-callout
    padding 10px
    margin 20px 0
    border 1px solid #eee
    border-left-color #1b809e
    border-left-width 5px
    border-radius 3px

.form-col .form-group
    lost-column 1/2
    +below('m')
        lost-column 1/1
.wrapper
    lost-center 280px
    position relative

    +above('s')
        max-width 80%

    +above('m')
        max-width 640px

    +above('l')
        max-width 980px

    +above('xl')
        max-width 1220px

.col-md-50
    lost-column: 1/1

    + .col-md-50
        margin-top 1em

    +above('m')
        lost-column: 1/2

        + .col-md-50
            margin-top 0


.col-sm-100
    +below('m')
        lost-column 1/1

.row
    lost-utility clearfix
.clearfix
    clear both